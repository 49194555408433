<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>

        <v-spacer />

        <v-btn icon small @click="hideFilter = !hideFilter">
          <v-icon small color="primary">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-show="!hideFilter" class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              :class="{ 'v-input-has-value ': query.name }"
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              id="name"
              placeholder="e.g. 2 Northdown"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              :class="{ 'v-input-has-value ': query.ticketSold }"
              v-on:keypress="isNumber($event)"
              outlined
              dense
              hide-details
              clearable
              name="ticketsold"
              label="Ticket Sold"
              v-model="query.ticketSold"
              id="ticketsold"
              placeholder="e.g. 25"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-menu
              ref="mfilterEventDate"
              v-model="mfilterEventDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterEventDate')"
                    dense
                    label="Event Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseEventDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterEventDate" @input="(query.filterEventDate.length === 2) ?  eventDatePicker=false :  eventDatePicker = true " range  @change="handeleEventDate">
                <v-btn 
                  text color="primary" @click="mfilterEventDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearEventDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              :class="{ 'v-input-has-value ': query.status }"
              v-model="query.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              :class="{ 'v-input-has-value ': query.genre }"
              v-model="query.genre"
              :items="genres"
              :item-text="'name'"
              :item-value="'name'"
              label="Event Category"
              dense
              clearable
              @click:clear="clear('genre')"
              outlined
            ></v-autocomplete>
          </v-col>

          
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="10" md="4" xl="3">
            <v-tabs v-model="query.tabStatus">
              <v-tab href="#upcoming" @click="tabChange('upcoming')">Upcoming</v-tab>
              <v-tab href="#all" @click="tabChange('all')">All</v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="2" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-btn block outlined color="primary" @click="exportExcel()" :loading="loading.export">Export</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.title`]="{ item }">
          <span
            >{{ item.title }}</span
          >
        </template>
        <template v-slot:[`item.tickets`]="{ item }">
          <span>{{ item.tickets.totalPurchaseCount }}/{{ item.tickets.totalAvailableCount }}</span>
        </template>
        <!--template v-slot:[`item.progress`]="{ item }">
          <span
            ><v-progress-linear
              :value="
                item.tickets.totalPurchaseCount
                  ? Math.round((item.tickets.totalPurchaseCount / item.tickets.totalAvailableCount) * 100)
                  : 0
              "
              rounded
              height="8"
              color="primary"
            ></v-progress-linear
          ></span>
        </template-->
        <template v-slot:[`item.startsAt`]="{ item }">
          <span>{{ item.startsAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <!-- <template v-slot:[`item.startsAt`]="{ item }">
          <span>{{moment(item.startsAt).format('DD/MM/YYYY HH:mm')}}</span>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                title="Callover List"
                class="mr-1"
                @click="goToCalloverList(item)"
              >
                mdi-form-select
              </v-icon>
            </template>
            <span>Callover List</span>
          </v-tooltip>         
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';

export default {
  data() {
    return {
      page: 1,
      hideFilter: false,
      eventDatePicker: false,
      query: {
        name: '',
        ticketSold: '',
        upcoming: true,
        payout: '',
        status: '',
        date: '',
        genre: '',
        page: 1,
        venues: [],
        tabStatus: 'upcoming',
        eventStarts: '',
        eventEnds: '',
        filterEventDate: [],
      },
      statuses: [
        { text: 'Draft', value: 'draft' },
        { text: 'Vetting', value: 'vetting' },
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Paused', value: 'paused' },
        { text: 'Cancelled', value: 'cancelled' },
        // { text: 'Private', value: 'private' },
      ],
      genres: [],
      totalPage: '',
      headers: [
        { text: 'Actions', value: 'actions', sortable: false, width: '100px' },
        { text: 'Date & Time', value: 'startsAt', sortable: false, width: '200px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
          width: '300px',
        },
        { text: 'Venue', value: 'venueName', sortable: false, width: '200px' },
        { text: 'Promoter', value: 'promoter', sortable: false, width: '300px' },
        { text: 'Tickets', value: 'tickets', sortable: false, width: '100px' },
        //{ text: 'Tickets Progress', value: 'progress', sortable: false, width: '200px' },
        { text: 'Status', value: 'status', sortable: false, width: '100px' },
        //{ text: 'Category', value: 'genre', sortable: false, width: '100px' },  
      ],
      promoterId: '',
      desserts: [],
      loading: {
        export: false,
      },
      isValidEventData: true,
      mfilterEventDate: false,
    };
  },
  computed: {
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.query.filterEventDate);
    }
  },
  created() {
    this.promoterId = 'all';
    Object.assign(this.query, this.$getState());
    if (this.query.tabStatus === 'upcoming') {
      this.query.upcoming = true;
    } else {
      this.query.upcoming = '';
    }
    this.query.venues = this.$user.venues;
    this.getEvents();
    this.getGenres();
  },
  methods: {
    async getEvents() {
      this.$setState(this.query);
      console.log("🚀 ~ file: list.vue ~ line 321 ~ created ~ this.query.venues", this.query.venues)
      const data = await PromoterEventService.get(this.promoterId, this.query);
      if (data) {
        this.desserts = data.events;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    async getGenres() {
      const response = await PromoterEventService.getGenreSearch({ limit: 'all'});
      if (response) {
        this.genres = response.genres;
      }
    },
    tabChange(data) {
      this.query.page = 1;
      this.desserts = [];
      if (data === 'upcoming') {
        this.query.upcoming = true;
      } else {
        this.query.upcoming = '';
      }
      this.getEvents();
    },
    resetFilter() {
      this.query.name = '';
      this.query.ticketSold = '';
      this.query.status = '';
      this.query.payout = '';
      this.query.date = '';
      this.query.genre = '';
      this.query.page = 1;
      this.getEvents();
    },
    goToCalloverList(item) {
      this.$next({ name: 'venue.events.callover-list', params: { id: item._id } });
    },
    clear(field) {
      this.query[field] = '';
      this.getEvents();
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.query['eventStarts'] = '';
      this.query['eventEnds'] = '';
      this.query.filterEventDate = [];
      this.getEvents();
    },
    async exportExcel() {
      this.loading.export = true;
      const exportdata = await PromoterEventService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Event-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
    handeleEventDate(val) {
      this.isValidEventData = this.$validateDateRange(val);
    }
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.genre': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.genre && this.query.genre.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.genre) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.ticketSold': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.ticketSold && this.query.ticketSold.length > 0) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.ticketSold) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.status': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.status && this.query.status.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.status) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.payout': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.payout && this.query.payout.length > 0) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.payout) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.date': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.date && this.query.date.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.date) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.page': function() {
      this.getEvents();
    },
    'query.filterEventDate': function() {
      if (this.query.filterEventDate.length > 1 && this.isValidEventData) {
        this.query.page = 1;
        this.query.eventStarts = this.query.filterEventDate[0];
        this.query.eventEnds = this.query.filterEventDate[1];
        this.getEvents();
      }
    }
  },
};
</script>
